import React, { useEffect, useState } from 'react';
import Container from '../components/atoms/Container/Container';
import Layout from '../components/organisms/Layout/Layout';
import { graphql } from 'gatsby';
import * as styles from './sitemap.module.scss';
import Banner from '../components/atoms/Banner/Banner';
import Seo from '../components/organisms/Seo/Seo';
import { isMadPawsStore } from '../common/util';

const CmaxSitemapPage = ({ data }) => {
  const allPathUrls = data.allFile.edges[0].node.childrenSearchPageDataCsv || [];
  const [urlsData, setUrlsData] = useState([]);
  useEffect(() => {
    const dataUrlsSorted = allPathUrls.sort((a, b) => {
      let fa = a.title;
      let fb = b.title;
      if (fa < fb) {
        return -1;
      }
      if (fa > fb) {
        return 1;
      }
      return 0;
    });
    setUrlsData(dataUrlsSorted);
  }, []);

  if (!data.allFile.edges.length > 0) return;

  return (
    <Layout className={styles.root}>
      <Seo
        title={`CMAX Sitemap - ${isMadPawsStore() ? 'Mad Paws Pet Store' : 'Pet Chemist Online'}`}
        description={`CMAX Sitemap - ${
          isMadPawsStore() ? 'Mad Paws Pet Store' : 'Pet Chemist Online'
        }`}
      ></Seo>
      <div className="banner">
        <Banner maxWidth={'650px'} name={`CMAX Sitemap`} height={'350px'} bgColor={`#f4f9fe`} />
      </div>
      <Container size="medium">
        <>
          {urlsData.length > 0 &&
            urlsData.map(pageData => (
              <>
                <a href={pageData.path} target="__blank">
                  {pageData.title}
                </a>
                <br></br>
              </>
            ))}
        </>
      </Container>
    </Layout>
  );
};

export default CmaxSitemapPage;

export const query = graphql`
  query {
    allFile(filter: { relativePath: { eq: "SearchPageData.csv" } }) {
      edges {
        node {
          childrenSearchPageDataCsv {
            id
            metaTitle
            meta_description
            path
            search_query
            title
          }
        }
      }
    }
  }
`;
